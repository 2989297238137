<template>
    <div class="NavigationLeftSmall">
        <v-navigation-drawer permanent dark color="disiBluegreyLight" width="90px">
            <v-list nav class="pa-0 mr-4">
                <v-list-item-group>
                    <v-list-item @click="changeStation(1)" :disabled="disabled"
                                 :class="{'disiDarkblue': activeStationId === 1}"
                                 v-if="stations[0].connected === 'connected'">
                        <v-list-item-title class="font-weight-regular">1</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="disiLightIrisblue" v-else disabled>
                        <v-list-item-title class="font-weight-regular">1</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeStation(2)" :disabled="disabled"
                                 :class="{'disiDarkblue': activeStationId === 2}"
                                 v-if="stations[1].connected === 'connected'">
                        <v-list-item-title class="font-weight-regular">2</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="disiLightIrisblue" v-else disabled>
                        <v-list-item-title class="font-weight-regular">2</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeStation(3)" :disabled="disabled"
                                 :class="{'disiDarkblue': activeStationId === 3}"
                                 v-if="stations[2].connected === 'connected'">
                        <v-list-item-title class="font-weight-regular">3</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="disiLightIrisblue" v-else disabled>
                        <v-list-item-title class="font-weight-regular">3</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="changeStation(4)" :disabled="disabled"
                                 :class="{'disiDarkblue': activeStationId === 4}"
                                 v-if="stations[3].connected === 'connected'">
                        <v-list-item-title class="font-weight-regular">4</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="disiLightIrisblue" v-else disabled>
                        <v-list-item-title class="font-weight-regular">4</v-list-item-title>
                    </v-list-item>

                    <router-link to="/stations-overview" v-if="!noBackButton">
                        <v-list-item>
                            <v-list-item-title class="back">&#8249;</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/station-testdetail" v-if="TestBack">
                        <v-list-item>
                            <v-list-item-title class="back">&#8249;</v-list-item-title>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: "NavigationLeftSmall",
    props: {
        noBackButton: {
            type: Boolean,
            default: false
        },
        TestBack: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapState([
            'activeStationId',
            'stations',
        ]),
    },
    methods: {
        ...mapMutations([
            'changeStation',
        ]),
    }
}
</script>

<style scoped lang="scss">

.v-list-item {
    background-color: $disiDarkblueLight !important;
    height: 60px;

    .v-list-item__title {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: white;

        &.back {
            font-size: 1.8rem;
            padding-top: 3px;
        }
    }
}

.v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
    margin-bottom: 10px;
}

.theme--dark.v-list-item--active::before {
    opacity: 0;
}

</style>