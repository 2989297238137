<template>
    <div class="AdjustmentPosition full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p style="font-weight: 500">{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-8"
                                id="pathdialog-list"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''" @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <navigation-left-small noBackButton disabled/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
<!--                Start-->
                <p class="pa-3" v-if="screen === 'start'"> {{ $t('adjCal.securityInfo') }}</p>
                <p class="pa-3" v-if="screen === 'start'"> {{ $t('adjCal.adjustPosStartTitle') }}</p>
<!--                Push-->
                <p class="pa-3" v-if="screen === 'push'"> {{ $t('adjCal.adjustPosPushInfo') }}</p>
                <div class="pa-3 mt-4 moveButtons" v-if="screen === 'push'">
                    <v-btn elevation="0" color="disiIrisblue" class="content-btn mb-4 arrowUp" :loading="loadingUp" :disabled="loadingUp || loadingDown" @click="moveBasket(1); loadingUp = true;"><div class="icon"></div>{{ $t('adjCal.up') }}</v-btn>
                    <v-btn elevation="0" color="disiIrisblue" class="content-btn arrowDown" :loading="loadingDown" :disabled="loadingDown || loadingUp" @click="moveBasket(-1); loadingDown = true;"><div class="icon"></div>{{ $t('adjCal.down') }}</v-btn>
                </div>
<!--                Finish-->
                <p class="pa-3" v-if="screen === 'finish'"> {{ $t('adjCal.adjustPosFinishTitle') }}</p>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
<!--            4-->
            <footer-button v-if="screen != 'finish'" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="export" :successCheck="showExportSuccess" :progress="showExportProgress" @footerButtonClick="pathmodal = true" :class="{'disabled': !checkUserRights('adjCalExport')}"></footer-button>
<!--            5-->
            <footer-button v-if="screen != 'finish'" buttonFunction="cancel" @footerButtonClick="goToAdjustment" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printReport" :class="{'disabled': !checkUserRights('adjCalPrint')}"></footer-button>
<!--            6-->
            <footer-button v-if="screen === 'start'" buttonFunction="start" @footerButtonClick="startAdjust"></footer-button>
            <footer-button v-if="screen === 'push'" buttonFunction="ok" @footerButtonClick="goOn"></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="ok" @footerButtonClick="goToAdjustment"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import NavigationLeftSmall from "@/components/NavigationLeftSmall.vue";
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'AdjustmentPosition',
    components: {
        FooterButton,
        NavigationLeftSmall
    },
    props: {
    },
    data () {
        return{
            screen: 'start',
            calibrationId: 0,
            loadingUp: false,
            loadingDown: false,
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            pathmodal: false,
            exportPath: '',
        }
    },
    computed: {
        ...mapState([
            'activeStationId',
            'pathlist',
        ]),
    },
    methods:{
        goToAdjustment(){
            this.$router.push('adjustment');
        },
        startAdjust(){
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjustPosition/start', {id: this.activeStationId}])
            .then(response =>{
                if (response.status === 200){
                    this.screen = 'push';
                    this.calibrationId = response.data.calibrationId;
                }
            })
        },
        moveBasket(value){
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjustPosition/move', {'calibrationId': this.calibrationId, move: value}])
                .then(() =>{
                    this.loadingUp = false;
                    this.loadingDown = false;
                })
        },
        goOn(){
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjustPosition/finished', {'calibrationId': this.calibrationId}])
                .then(response =>{
                    if (response.status === 200) {
                        this.screen = 'finish'
                    }
                })
        },
        printReport(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjust/print', {'adjustmentId': [this.calibrationId]}])
                .then(response =>{
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(()=>{
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                })
        },
        doExport(){
            this.showExportProgress = true;
                this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjust/export', {'adjustmentId': [this.calibrationId], 'pathId': this.exportPath}])
                .then(response =>{
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(()=>{
                            this.showExportSuccess = false;
                        }, 800)
                    }
                })
        },
    },
    beforeRouteLeave (to, from, next) {
        if(this.screen === 'start' || this.screen === 'finish'){
            next()
        } else {
            this.$confirm({
                message: this.$t('alerts.cancelAdjustment'),
                show: true,
                cancelText: this.$t('general.cancel'),
                continueText: this.$t('general.continue')
            })
                .then(() => {
                    // continue
                    this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjust/cancel', {'adjustmentId': this.calibrationId}])
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
    mixins: [mixins],
}
</script>
<style scoped lang="scss">

.v-application .content-btn {
    min-width: 100px;
    width: 157px;
    justify-content: left;
}
.arrowUp{
    .icon{
        &::before{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            margin-bottom: 3px;
            background-image: url("../assets/up_weiss.svg");
            background-size: contain;
            background-position: center;
        }
    }
}
.arrowDown{
    .icon{
        &::before{
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            margin-bottom: 3px;
            background-image: url("../assets/down_weiss.svg");
            background-size: contain;
            background-position: center;
        }
    }
}
</style>